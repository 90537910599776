<template>
  <div class="verification-page">
    <vuestic-widget v-if="user" :loading="loading">
      <template slot="header">
        <span class="title">SMS Registration</span>
      </template>
      <b-tabs small card v-model="tabIndex">
        <b-tab title="New Registration">
          <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="agencyForm">
            <form @submit.prevent="handleSubmit(onSubmit)" class="form-wrapper">
              <div class="row mt-3">
                <div v-if="campaign.status" class="col-md-12 my-3">
                  <div class="alert alert-primary d-block" :class="{ 'alert-danger': campaign.status === 'failed' }">
                    <span class="d-block text-primary font-weight-bold">Status: {{ campaign.statusStr }} <i
                        v-if="isCampaignProcessing" class="fa fa-refresh fa-spin"></i></span>
                    <br />
                    <span v-if="isCampaignProcessing" class="d-block font-weight-bold">This registration is
                      under review, and may take several weeks to complete. You may check back on
                      this registration status in a couple of days.</span>
                    <span v-if="campaign.status === 'failed'">
                      SMS Registration is failed due to unexpected reason. Please contact admin at <a
                        :href="`mailto:${agencyEmail}`" target="_blank"
                        class="font-weight-bold text-decoration-underline">{{
                          agencyEmail }}</a> for more information!
                    </span>
                  </div>
                </div>
                <div v-if="!twBrandRegistered" class="col-md-12 my-3">
                  <div class="alert alert-primary d-block">
                    You are not allowed to submit <b>SMS registration</b> at the moment, <router-link
                      class="font-weight-bold text-primary text-decoration-underline"
                      :to="{ name: 'agency.twilioProfile.index' }">Click Here</router-link> to add and get
                    approved twilio profile before submission.<br />
                    Please contact admin at <a :href="`mailto:${agencyEmail}`" target="_blank"
                      class="font-weight-bold text-decoration-underline">{{
                        agencyEmail }}</a> for more information!
                  </div>
                </div>
                <div class="col-md-12 mt-3">
                  <span class="lelabel">SMS Campaign Type</span>
                  <div class="d-block mt-2">
                    <div class="radio abc-radio abc-radio-primary">
                      <input type="radio" name="campaignType" id="campaignTypeStarter" value="starter"
                        v-model="campaign.campaignType" :disabled="isCampaignSubmitted" />
                      <label for="campaignTypeStarter">
                        <span class="abc-label-text">Starter</span>
                      </label>
                    </div>
                    <p>If your agency account sends fewer
                    than <b>2000</b> text messages per day and uses 2 or fewer phone numbers.<br/>There is <span class="text-primary font-weight-bold">$1.5 / monthly</span> campaign fee.</p>
                    <div class="radio abc-radio abc-radio-primary mt-4">
                      <input type="radio" name="campaignType" id="campaignTypeStandard" value="standard"
                        v-model="campaign.campaignType" :disabled="isCampaignSubmitted" />
                      <label for="campaignTypeStandard">
                        <span class="abc-label-text">Standard</span>
                      </label>
                    </div>
                    <p> If your agency account sends more
                      than <b>2000</b> text messages per day or uses 3 or more phone numbers. <br/>There is <span class="text-primary font-weight-bold">$10 / monthly</span> campaign fee.
                    </p>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <text-input name="Website" label="Website" v-model="campaign.website" validate="required"
                    :disabled="isCampaignSubmitted" :margin-bottom="0" />
                  <span class="d-block mt-2 text-primary">
                    <small class="font-weight-bold">Website that you have installed the widget/form on. <br /><b class="text-danger">GDPR/CONSENT CHECK BOX</b> should be
                      turned on for this widget.</small>
                  </span>
                </div>
                <div class="col-md-6"></div>
              </div>
              <div class="row mt-5">
                <div class="col-md-12 d-flex justify-content-center">
                  <button v-if="campaign.status" type="button" class="btn btn-primary mr-2" :disabled="processing"
                    @click="onRefresh">
                    <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                      style="margin: auto;" />
                    <span v-else>Refresh</span>
                  </button>
                  <button v-if="canSubmit" class="btn btn-primary"
                    :disabled="invalid || processing || !twBrandRegistered">
                    <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                      style="margin: auto;" />
                    <span v-else>{{ submitBtnLabel }}</span>
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </b-tab>
        <b-tab title="Add Existing Registration">
          <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="agencyForm">
            <form @submit.prevent="handleSubmit(onSubmit)" class="form-wrapper">
              <div class="row mt-3">
                <div class="col-md-12">
                  <p class="font-weight-bold">If you already have Campaign Registration submitted manually, Please provide
                    us <b class="text-primary">Message Service ID</b> to sync Data from Twilio.</p>
                  <p class="font-weight-bold"><a
                      href="https://console.twilio.com/us1/develop/sms/regulatory-compliance/campaigns" target="_blank"
                      class="font-weight-bold text-decoration-underline text-primary">Click Here</a> and You can find your
                    own campaign
                    to get <b class="text-primary">Message Service ID</b> related to.</p>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6">
                  <text-input name="MessageServiceId" label="Message Service ID" v-model="campaign.message_serice_id"
                    validate="required" :disabled="isCampaignSubmitted" :margin-bottom="0" />
                </div>
                <div class="col-md-6"></div>
              </div>
              <div class="row mt-5">
                <div class="col-md-12 d-flex justify-content-center">
                  <button class="btn btn-primary" :disabled="invalid || processing || !twBrandRegistered">
                    <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                      style="margin: auto;" />
                    <span v-else>Update</span>
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </b-tab>
      </b-tabs>
    </vuestic-widget>
    <vuestic-modal :isOpen="isOpenConfirmDelete" :hideDefaultActions="true" @cancel="isOpenConfirmDelete = false"
      :cancelShown="false" :okShown="false">
      <span slot="title" class="text-primary font-weight-bold">SMS Registration</span>
      <div v-if="tabIndex == 0">
        <p>You are going to submit SMS registration. Please confirm information you put is accurate.</p>
        <p>( Please make sure website <b class="text-primary">{{ campaign.website }}</b> has widget installed on and <b class="text-primary">GDPR Check Box</b> is enabled on the widget ! )</p>
      </div>
      <div v-else>
        <p>You are going to submit exiting SMS registration to our system. Please confirm <b class="text-primary">Message Service ID</b> you put is accurate.</p>
      </div>
      <div class="row mt-5">
        <div class="col-md-12 d-flex justify-content-center">
          <button @click="submit" class="btn btn-primary" :disabled="processing">
            <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
            <span v-else>Confirm</span>
          </button>
        </div>
      </div>
    </vuestic-modal>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      loading: false,
      processing: false,

      campaign: {
      },
      tabIndex: 0,
      isOpenConfirmDelete: false,
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {
    onRefresh() {
      this.loadData()
    },
    loadData() {
      this.loading = true
      this.$store
        .dispatch('twilio/getCampaign')
        .then((res) => {
          this.loading = false
          if (!res.success) {
            this.errors = res.data
            Vue.$toast.open({
              message: res.message,
              type: 'error',
            })
          } else {
            this.campaign = res.data
            if (!this.campaign.campaignType) {
              this.campaign.campaignType = 'starter'
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },

    onSubmit() {
      this.isOpenConfirmDelete = true
    },

    submit() {
      if (this.tabIndex === 0) {
        this.submitRegistration()
      } else {
        this.syncRegistration();
      }
    },

    submitRegistration() {
      this.processing = true
      this.$store
        .dispatch('twilio/registerCampaign', this.campaign)
        .then((res) => {
          this.processing = false
          this.isOpenConfirmDelete = false
          if (!res.success) {
            this.errors = res.data
            Vue.$toast.open({
              message: res.message,
              type: 'error',
            })
          } else {
            this.onRefresh();
          }
        })
        .catch((err) => {
          console.log(err)
          this.processing = false
        })
    },

    syncRegistration() {
      this.processing = true
      this.$store
        .dispatch('twilio/syncCampaign', this.campaign)
        .then((res) => {
          this.processing = false
          this.isOpenConfirmDelete = false
          if (!res.success) {
            this.errors = res.data
            Vue.$toast.open({
              message: res.message,
              type: 'error',
            })
          } else {
            this.onRefresh();
          }
        })
        .catch((err) => {
          console.log(err)
          this.processing = false
        })
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },
    business() {
      return this.user && this.user.business
    },
    agency() {
      return this.user && this.user.agency
    },
    agencyEmail() {
      return this.agency && this.agency.email || '';
    },
    twBrandRegistered() {
      return this.agency && this.agency.tw_brand_sid
    },
    isCampaignProcessing() {
      return this.campaign.status && ['pending', 'in_progress'].includes(this.campaign.status)
    },
    isCampaignSubmitted() {
      return this.campaign.status && ['verified', 'pending', 'in_progress'].includes(this.campaign.status)
    },
    canSubmit() {
      return !this.campaign.status || ['failed'].includes(this.campaign.status)
    },

    submitBtnLabel() {
      let res = 'Submit'
      if (this.campaign.status && ['failed'].includes(this.campaign.status))
        res = 'Resubmit'
      return res
    },
  }
}
</script>
  
<style lang="scss">
.verification-page {
  .widget-body {
    padding: 0;
  }

  .form-wrapper {
    max-width: 1024px;
  }
}
</style>